import CourseCommand from "./course";
import CVCommand from "./cv";
import DefaultCommand from "./default";
import ManCommand from "./man";
import { EscSeq } from "../const";

export default class AboutCommand extends DefaultCommand {
    static name = "about";
    static usage = "about [options]";
    static description = `Prints short information about the author.`;

    run(term) {
        this.validate();

        if (this.shortFlags && this.shortFlags[0] === "-h") {
            ManCommand.printHelp(term, AboutCommand);
            return
        }

        term.printText(`
I'm Olexandr Borysov, CTO at SKELAR and a passionate tech entrepreneur. 
I specialize in building strong teams and crafting exceptional products, with a focus on modern tech stacks and efficient workflows. 
For an in-depth look at my career journey, run the ${EscSeq.colorMagenta}${CVCommand.name}${EscSeq.resetStyles} command.
`);
    }
}
