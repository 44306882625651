import DefaultCommand from "./default";
import { CommandsRegistry } from "../command_registry";

export default class ManCommand extends DefaultCommand {
    static name = "man";
    static usage = "man [options] <command>";
    static description = `Shows manual for specified command.`;

    run(term) {
        this.validate();

        if (this.shortFlags && this.shortFlags[0] === "-h") {
            ManCommand.printHelp(term, ManCommand);
            return
        }

        if (this.args.length === 0) {
            term.printText("What manual page do you want?");
            return
        }

        let name = this.args[0];
        let cmd = CommandsRegistry[name];
        if (!cmd) {
            term.printError(`No manual entry for ${name}`);
            return
        }

        ManCommand.printHelp(term, cmd)
    }

    static validateArgs(args) {
        if (args.length > 1) {
            throw new Error("Only one manual per time supported.")
        }
    }

    static printHelp(term, cmd) {
        let text = cmd.description ? `${cmd.description}\n` : "";
        text += cmd.usage ? `Usage:\n\t${cmd.usage}\n` : "";

        if (cmd.knownShortFlags && Object.getOwnPropertyNames(cmd.knownShortFlags).length > 0) {
            text += `Options:`;

            for (let [flag, description] of Object.entries(cmd.knownShortFlags)) {
                text += `\n\t${flag}\t – ${description}`;
            }
        }

        term.printText(text);
    }
}
