import DefaultCommand from "./default";
import ManCommand from "./man";

export default class CVCommand extends DefaultCommand {
    static name = "cv";
    static usage = "cv [options]";
    static description = `Opens CV in a popup window.`;
    static link = "https://www.linkedin.com/in/alexander-borisov-6b320147/";

    run(term) {
        this.validate();

        if (this.shortFlags && this.shortFlags[0] === "-h") {
            ManCommand.printHelp(term, CVCommand);
            return
        }

        term.printText(`Check my linkedin profile ${CVCommand.link}.`);
        window.open(CVCommand.link, "_blank");
    }
}
