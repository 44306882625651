export const EscSeq = Object.freeze({
    ESC: "\x1b[",
    clear: "\x1b[H\x1b[2J",
    eraseEndLine: "\x1B[K",
    resetStyles: "\x1b[0m",
    removeLine: "\x1b[2K\r",
    colorBlue: "\x1b[1;36m",
    colorGreen: "\x1b[1;32m",
    colorMagenta: "\x1b[1;35m",
    colorRed: "\x1b[1;31m",
    colorWhite: "\x1b[1;37m",
    colorYellow: "\x1b[1;33m",
});
