import DefaultCommand from "./default";
import ManCommand from "./man";

export default class BlogCommand extends DefaultCommand {
    static name = "blog";
    static usage = "blog [options]";
    static description = `Prints link to the blog.`;

    run(term) {
        this.validate();

        if (this.shortFlags && this.shortFlags[0] === "-h") {
            ManCommand.printHelp(term, BlogCommand);
            return
        }

        term.printText(`I have my personal blog in telegram.
Try it out. Maybe you will find something special for you:
https://t.me/tg_neproblema`);
    }
}
