import DefaultCommand from "./default";
import ManCommand from "./man";

export default class ContactCommand extends DefaultCommand {
    static name = "contact";
    static usage = "contact [options]";
    static description = `Shows contacts.`;

    run(term) {
        this.validate();

        if (this.shortFlags && this.shortFlags[0] === "-h") {
            ManCommand.printHelp(term, ContactCommand);
            return
        }

        term.printText(`Email: \tinfo@cheshir.me
Linkedin: https://www.linkedin.com/in/alexander-borisov-6b320147
Github:   https://github.com/cheshir`);
    }
}
