import DefaultCommand from "./default";
import { CommandsRegistry } from "../command_registry";

export default class HelpCommand extends DefaultCommand {
    static name = "help";
    static usage = "help [options]";
    static description = "Contains list of all supported commands.";

    run(term) {
        let textChunks = ["Available commands:"];
        for (let name of Object.keys(CommandsRegistry)) {
            textChunks.push(`\t${name}\t – ${CommandsRegistry[name].description}`);
        }

        term.printText(textChunks.join("\r\n"));
    }
}
