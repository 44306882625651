import ContactCommand from "./contact";
import DefaultCommand from "./default";
import ManCommand from "./man";
import { EscSeq } from "../const";

export default class ClearCommand extends DefaultCommand {
    static name = "clear";
    static usage = "clear [options]";
    static description = `Clears the terminal screen.`;

    run(term) {
        this.validate();

        if (this.shortFlags && this.shortFlags[0] === "-h") {
            ManCommand.printHelp(term, ClearCommand);
            return
        }

        term.xterm.write(EscSeq.clear);
    }
}
