export default class Parser {
    static token = {
        ARG: "arg",
        CMD_NAME: "cmd",
        DELIMITER: "delimiter",
        EOF: "eof",
        SHORT_FLAG: "short_flag",
    };

    static stateMachine = {
        start: [
            {
                name: "commandName",
                scan: Parser.scanCommandName,
                type: Parser.token.CMD_NAME,
            },
        ],
        commandName: [
            {
                name: "commandDelimiter",
                scan: Parser.scanDelimiter,
                type: Parser.token.DELIMITER,
            },
        ],
        commandDelimiter: [
            {
                name: "shortFlag",
                scan: Parser.scanShortFlag,
                type: Parser.token.SHORT_FLAG,
            },
            {
                name: "arg",
                scan: Parser.scanArg,
                type: Parser.token.ARG,
            },
        ],
        shortFlag: [
            {
                name: "flagDelimiter",
                scan: Parser.scanDelimiter,
                type: Parser.token.DELIMITER,
            },
        ],
        flagDelimiter: [
            {
                name: "shortFlag",
                scan: Parser.scanShortFlag,
                type: Parser.token.SHORT_FLAG,
            },
            {
                name: "arg",
                scan: Parser.scanArg,
                type: Parser.token.ARG,
            },
        ],
        arg: [
            {
                name: "argDelimiter",
                scan: Parser.scanDelimiter,
                type: Parser.token.DELIMITER,
            },
        ],
        argDelimiter: [
            {
                name: "arg",
                scan: Parser.scanArg,
                type: Parser.token.ARG,
            },
        ],
    };

    parse(input) {
        let tokens = this.tokenize(input.trim());
        if (!tokens.length) {
            throw new Error("Invalid syntax");
        }

        let cmdToken = tokens[0];
        if (cmdToken.type !== Parser.token.CMD_NAME) {
            throw new Error("Input must starts with command name");
        }

        let parsed = {
            cmd: cmdToken.value,
            shortFlags: [],
            args: [],
        };

        for (let i = 1; i < tokens.length; i++) {
            switch (tokens[i].type) {
                case Parser.token.ARG:
                    parsed.args.push(tokens[i].value);
                    break;
                case Parser.token.SHORT_FLAG:
                    parsed.shortFlags.push(tokens[i].value);
                    break;
            }
        }

        return parsed
    }

    /**
     * CMD: CMD_NAME FLAGS ARGS EOF
     * CMD_NAME: [a-z-]+
     * FLAGS: SHORT_FLAG | FLAGS
     * SHORT_FLAG: -[a-z]
     * ARGS: ARG | ARGS
     * ARG: [^\s]+
     * @param input
     */
    tokenize(input) {
        let scannedTokens = [];
        let currentState = "start";

        mainLoop:
            for (let i = 0; i < input.length; i++) {
                let transitions = Parser.stateMachine[currentState];
                for (let transition of transitions) {
                    let result = transition.scan(input, i);
                    if (!result.scanned) {
                        continue;
                    }

                    scannedTokens.push({
                        type: transition.type,
                        value: result.scanned,
                    });

                    i = result.i;
                    currentState = transition.name;

                    // Scan next token.
                    continue mainLoop;
                }

                const shrinkLength = 20;
                let endSlice = input + shrinkLength <= input.length ? input + shrinkLength : input.length;

                throw new Error(`Failed to parse "${input.substring(i, endSlice)}". Check help.`);
            }

        return scannedTokens
    }

    /**
     * [\w.-]+([ \t]|$)
     * @param input String
     * @param i Number
     * @returns {{scanned: string, i: number}}
     */
    static scanCommandName(input, i) {
        const validNonLetterChars = "-._";
        let cursor = i;

        for (; cursor < input.length; cursor++) {
            let char = input[cursor];
            let valid = Parser.isLetter(char) || validNonLetterChars.includes(char);
            if (!valid) {
                break;
            }
        }

        if (cursor === i) {
            return { scanned: "", i: i }
        }

        return { scanned: input.substring(i, cursor), i: cursor-1 }
    }

    static scanShortFlag(input, i) {
        if (i+1 < input.length && input[i] === "-" && Parser.isLetter(input[i+1])) {
            return {
                scanned: input[i] + input[i+1],
                i: i+1,
            }
        }

        return { scanned: "", i: i }
    }

    static scanArg(input, i) {
        // Temporary solution.
        return Parser.scanCommandName(input, i)
    }

    /**
     * [ \t] or EOF.
     * @param input String
     * @param i Number
     */
    static scanDelimiter(input, i) {
        let cursor = i;

        for (; cursor < input.length; cursor++) {
            if (!Parser.isSpace(input[cursor])) {
                break;
            }
        }

        if (cursor === input.length) {
            return { scanned: "EOF", i: cursor }
        }

        if (cursor === i) {
            return { scanned: "", i: i }
        }

        return { scanned: input.substring(i, cursor), i: cursor-1 }
    }

    static isLetter(symbol) {
        return symbol >= "a" && symbol <= "z"
    }

    static isSpace(symbol) {
        return symbol === " " || symbol === "\t" || symbol === "\n" || symbol === "\r"
    }
}
