export default class DefaultCommand {
    static name;
    static usage;
    static description;
    static knownShortFlags = {
        "-h": "Print help message and exit.",
    };

    constructor(args, shortFlags) {
        this.args = args;
        this.shortFlags = shortFlags;
    }

    run(term) {
        throw new Error("Not implemented.");
    }

    validate() {
        this.constructor.validateFlags(this.shortFlags);
        this.constructor.validateArgs(this.args);
    }

    static validateFlags(flags) {
        for (let flag of flags) {
            if (!this.knownShortFlags.hasOwnProperty(flag)) {
                throw new Error("Invalid flag " + flag);
            }
        }
    }

    static validateArgs(args) {
        if (args.length > 0) {
            throw new Error("This command does not support arguments");
        }
    }
}
