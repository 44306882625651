import { Terminal } from "xterm";
import { App } from "./app";

const xterm = new Terminal({
    convertEol: true,
    cursorBlink: true,
    cursorStyle: "bar",
    disableStdin: false,
    drawBoldTextInBrightColors: true,
    // logLevel: "debug",
    rendererType: "canvas", // dom
    rightClickSelectsWord: true,
    cols: 80,
    rows: 28,
    theme: {
        background: "#110d17",
    },
});

new App(xterm, document.getElementById("terminal"));
