import DefaultCommand from "./default";
import ManCommand from "./man";

export default class CourseCommand extends DefaultCommand {
    static name = "course";
    static usage = "course [options]";
    static description = `Opens course details in a popup window.`;
    static link = "https://course.cheshir.me";

    run(term) {
        this.validate();

        if (this.shortFlags && this.shortFlags[0] === "-h") {
            ManCommand.printHelp(term, CourseCommand);
            return
        }

        term.printText(`In the early 2020 I started reading online-course "Dive into the Go" about programming and golang.
Course description: ${CourseCommand.link}.`);
        window.open(CourseCommand.link, "_blank");
    }
}
