import {FitAddon} from "xterm-addon-fit";
import {WebLinksAddon} from "xterm-addon-web-links";

import Term from "./term";
import Parser from "./parser";
import * as command from "./command"
import { CommandsRegistry } from "./command_registry"

export class App {
    constructor(term, container) {
        const fit = new FitAddon();
        term.loadAddon(fit);
        term.loadAddon(new WebLinksAddon());
        term.open(container);
        fit.fit();
        term.cursorBlink = true;

        this.term = new Term(term, new Parser());
        this.initCommands();
    }

    initCommands() {
        CommandsRegistry[command.AboutCommand.name] = command.AboutCommand;
        CommandsRegistry[command.BlogCommand.name] = command.BlogCommand;
        CommandsRegistry[command.ClearCommand.name] = command.ClearCommand;
        CommandsRegistry[command.ContactCommand.name] = command.ContactCommand;
        CommandsRegistry[command.CourseCommand.name] = command.CourseCommand;
        CommandsRegistry[command.CVCommand.name] = command.CVCommand;
        CommandsRegistry[command.HelpCommand.name] = command.HelpCommand;
        CommandsRegistry[command.ManCommand.name] = command.ManCommand;
    }
}
