import ManCommand from "./command/man";
import Parser from "./parser";
import { CommandsRegistry } from "./command_registry";
import { EscSeq } from "./const";

export default class Term {
    constructor(xterm, parser) {
        this.xterm = xterm;
        this.parser = parser;
        this.history = [];
        this.historyAge = 0; // Reversed cursor.
        this.prompt = `${EscSeq.colorYellow}guest${EscSeq.colorMagenta}@${EscSeq.colorGreen}cheshir.me ${EscSeq.colorBlue}➜ ${EscSeq.colorWhite}${EscSeq.resetStyles}`;
        this.promptLength = 19; // Without control symbols.
        this.currentInputLine = "";
        this.currentInputLineNextCharPos = 0;
        this.tmpInputLine = ""; // Used for forwarding history.
        this.greetings = `
Olexandr Borysov here, your friendly neighborhood CTO.
If you're not familiar with the terminal interface, don't worry. It's simple.
You just need to type some command and hit enter.
You won't break anything by typing commands (I hope).
List of all available commands you can get running ${EscSeq.colorRed}help${EscSeq.resetStyles} command.
Let's start with ${EscSeq.colorRed}about${EscSeq.resetStyles} command.`;
        this.printText(this.greetings);
        this.printPrompt();
        this.xterm.onKey((e) => this.inputHandler(e.key, e.domEvent));
    }

    inputHandler(key, event) {
        const printable = !event.altKey && !event.altGraphKey && !event.ctrlKey && !event.metaKey;
        const code = event.key;

        switch (code) {
            case "Backspace":
                this.backspace();
                break;
            case "Enter":
                this.addToHistory();
                const input = this.currentInputLine;
                if (!input || input.length === 0) {
                    this.printPrompt();
                    break;
                }

                this.resetCurrentInput();
                this.printNewLine();
                this.resetColor();
                if (input) {
                    this.exec(input);
                }
                this.printPrompt();
                break;
            case "ArrowUp":
                this.printPreviousCommand();
                break;
            case "ArrowDown":
                this.printNextCommand();
                break;
            case "ArrowLeft":
                this.moveCursorLeft(key);
                break;
            case "ArrowRight":
                this.moveCursorRight(key);
                break;
            default:
                if (printable) {
                    this.print(key)
                }
        }
    }

    /**
     * Ignore lines starting with space.
     */
    addToHistory() {
        if (this.currentInputLine.length === 0 || Parser.isSpace(this.currentInputLine[0])) {
            return
        }

        this.history.push(this.currentInputLine);
        this.historyAge = 0;
    }

    addToCurrentInputLine(char) {
        if (this.xterm.buffer.cursorX < this.promptLength) {
            return
        }

        this.currentInputLine = this.insert(this.currentInputLine, char, this.currentInputLineNextCharPos);
        this.currentInputLineNextCharPos++
    }

    backspace() {
        if (this.currentInputLineNextCharPos > 0) {
            this.cutCharFromCurrentInputLine(this.currentInputLineNextCharPos - 1);
            this.currentInputLineNextCharPos--;
            this.xterm.write('\b');
            this.printChar("") // Print rest of the string starting from this.currentInputLineNextCharPos.
        }
    }

    cutCharFromCurrentInputLine(position) {
        let head = this.currentInputLine.substring(0, position);
        let tail = this.currentInputLine.substring(position + 1, this.currentInputLine.length);
        this.currentInputLine = head + tail;
    }

    exec(input) {
        try {
            let config = this.parser.parse(input);
            let cmd = CommandsRegistry[config.cmd];
            if (!cmd) {
                throw new Error("Command not found");
            }

            try {
                (new cmd(config.args, config.shortFlags)).run(this);
            } catch (e) {
                this.printError(e);
                ManCommand.printHelp(this, cmd)
            }
        } catch (e) {
            this.printError(e)
        }
    }

    insert(str, char, i) {
        return str.substring(0, i) + char + str.substring(i);
    }

    moveCursorLeft(key) {
        if (this.xterm.buffer.cursorX !== this.promptLength) {
            this.currentInputLineNextCharPos--;
            this.print(key)
        }
    }

    moveCursorRight(key) {
        if (this.xterm.buffer.cursorX !== this.promptLength + this.currentInputLine.length) {
            this.currentInputLineNextCharPos++;
            this.print(key)
        }
    }

    updateCursorPosition() {
        this.moveCursorTo(this.promptLength + this.currentInputLineNextCharPos);
    }

    moveCursorTo(x, y) {
        if (typeof x !== 'number') {
            throw new TypeError('The `x` argument is required');
        }

        if (typeof y !== 'number') {
            this.xterm.write(EscSeq.ESC + (x + 1) + 'G');
            return
        }

        this.xterm.write(EscSeq.ESC + (y + 1) + ';' + (x + 1) + 'H');
    }

    print(char) {
        // For non-control chars.
        if (char.length === 1) {
            this.addToCurrentInputLine(char);
            this.printChar(char);

            return
        }

        this.xterm.write(char);
    }

    printChar(char) {
        let tail = this.currentInputLine.substring(this.currentInputLineNextCharPos);
        this.xterm.write(EscSeq.eraseEndLine + char + tail);
        this.updateCursorPosition();
    }

    printNewLine() {
        this.xterm.write("\r\n");
    }

    printCommandFromHistory() {
        const index = this.history.length - this.historyAge;
        this.currentInputLine = this.historyAge > 0 ? this.history[index] : this.tmpInputLine;
        this.currentInputLineNextCharPos = this.currentInputLine.length; // todo check
        this.removeLastLine();
        this.xterm.write(this.prompt + this.currentInputLine);
    }

    printError(err) {
        this.printText(`${EscSeq.colorRed}${err}${EscSeq.resetStyles}`);
    }

    printNextCommand() {
        if (this.historyAge === 0) {
            return
        }

        this.historyAge--;
        this.printCommandFromHistory();
    }

    printPreviousCommand() {
        if (this.history.length - this.historyAge === 0) {
            return
        }

        if (this.historyAge === 0) {
            this.tmpInputLine = this.currentInputLine;
        }

        this.historyAge++;
        this.printCommandFromHistory();
    }

    printPrompt() {
        this.xterm.write("\r\n" + this.prompt);
    }

    printText(text) {
        this.xterm.writeln(text);
    }

    removeLastLine() {
        this.xterm.write(EscSeq.removeLine)
    }

    resetColor() {
        this.xterm.write(EscSeq.resetStyles);
    }

    resetCurrentInput() {
        this.currentInputLine = "";
        this.currentInputLineNextCharPos = 0;
        this.tmpInputLine = "";
    }
}
